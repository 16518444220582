import React from "react";

import { graphql } from "gatsby";

import { useTranslation } from "gatsby-plugin-react-i18next";

import Divider from "components/Divider";
import Hero from "components/Hero";
import PageContainer from "components/PageContainer";
import QantevLink from "components/QantevLink";
import SEO from "components/SEO";

const CareersPage = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Hero
        title={t("Want to work with us?")}
        divider={<Divider icon={t("CAREERS")} />}
      >
        <div>
          <div style={{ marginBottom: "3em" }}>
            <p style={{ maxWidth: "45ch" }}>
              {t(
                "Are you curious about applying breakthroughs in mathematics and AI to the insurance sector?"
              )}
            </p>
            <p style={{ maxWidth: "45ch" }}>{t("Let's work together.")}</p>
          </div>
          <QantevLink
            as="a"
            $btn
            to="https://qantev.welcomekit.co/"
            target="_blank"
            rel="noopener"
          >
            {t("Job Openings")}
          </QantevLink>
        </div>
      </Hero>
    </PageContainer>
  );
};

export default CareersPage;

// eslint-disable-next-line react/prop-types
export const Head = ({ location: { pathname } }) => (
  <SEO title="Careers" pathname={pathname} />
);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["careers", "common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
